import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 50px auto;
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
`;

const Heading = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 28px;
  font-weight: bold;
`;

const SubHeading = styled.h3`
  margin-top: 20px;
  color: #ff4500;
  font-size: 22px;
  font-weight: bold;
`;

const Paragraph = styled.p`
  margin: 10px 0;
  line-height: 1.6;
  color: #555;
`;

const PrivacyPolicy = () => {
  return (
    <Container>
      <Heading>Gizlilik Politikası</Heading>
      
      <SubHeading>1. Giriş</SubHeading>
      <Paragraph>Biz, TkyExpress, gizliliğinizi korumayı ve kişisel verilerinizi güvenli bir şekilde işlemeyi taahhüt ediyoruz. Bu Gizlilik Politikası, uygulamamız üzerinden topladığımız bilgiler ve bu bilgilerin nasıl kullanıldığı, paylaşıldığı ve korunduğu hakkında sizi bilgilendirmektedir.</Paragraph>
      
      <SubHeading>2. Toplanan Bilgiler</SubHeading>
      <SubHeading>2.1. Kişisel Bilgiler</SubHeading>
      <Paragraph>Sitemizi veya uygulamamızı kullanırken, çeşitli nedenlerle sizden kişisel bilgiler talep edebiliriz. Bu bilgiler şunları içerebilir:</Paragraph>
      <ul>
        <li>İsim</li>
        <li>E-posta adresi</li>
        <li>Telefon numarası</li>
        <li>Adres</li>
        <li>Kullanıcı adı ve şifre</li>
        <li>Ödeme bilgileri</li>
      </ul>
      <SubHeading>2.2. Otomatik Olarak Toplanan Bilgiler</SubHeading>
      <Paragraph>Sitemizi veya uygulamamızı kullanırken, cihazınızdan otomatik olarak bazı bilgiler toplanabilir. Bu bilgiler şunları içerebilir:</Paragraph>
      <ul>
        <li>IP adresi</li>
        <li>Tarayıcı türü ve sürümü</li>
        <li>Cihaz türü</li>
        <li>Çerezler ve diğer izleme teknolojileri aracılığıyla toplanan bilgiler</li>
      </ul>
      
      <SubHeading>3. Bilgilerin Kullanımı</SubHeading>
      <Paragraph>Topladığımız bilgileri şu amaçlarla kullanabiliriz:</Paragraph>
      <ul>
        <li>Hizmetlerimizi sağlamak ve iyileştirmek</li>
        <li>Sizinle iletişime geçmek</li>
        <li>Müşteri desteği sağlamak</li>
        <li>Pazarlama ve promosyon faaliyetleri yürütmek</li>
        <li>Güvenliği sağlamak ve sahtekarlığı önlemek</li>
        <li>Yasal yükümlülüklere uymak</li>
      </ul>
      
      <SubHeading>4. Bilgilerin Paylaşımı</SubHeading>
      <Paragraph>Kişisel bilgilerinizi şu durumlarda üçüncü taraflarla paylaşabiliriz:</Paragraph>
      <ul>
        <li>Hizmet sağlayıcılarla işbirliği yaparak hizmetlerimizi sunmak</li>
        <li>Yasal zorunluluklar ve yasal süreçlere yanıt vermek</li>
        <li>Haklarımızı ve mülkiyetimizi korumak</li>
        <li>İzniniz doğrultusunda</li>
      </ul>
      
      <SubHeading>5. Çerezler ve Diğer İzleme Teknolojileri</SubHeading>
      <Paragraph>Web sitemiz ve uygulamamız, kullanıcı deneyimini iyileştirmek ve hizmetlerimizi kişiselleştirmek amacıyla çerezler ve diğer izleme teknolojilerini kullanır. Çerezler, tarayıcınıza yerleştirilen ve sitemizi nasıl kullandığınız hakkında bilgi toplayan küçük dosyalardır. Tarayıcı ayarlarınızdan çerezleri devre dışı bırakabilir veya silebilirsiniz.</Paragraph>
      
      <SubHeading>6. Bilgilerin Güvenliği</SubHeading>
      <Paragraph>Kişisel bilgilerinizin güvenliğini sağlamak için uygun teknik ve idari önlemler alıyoruz. Ancak, internet üzerinden yapılan veri iletimlerinin tamamen güvenli olduğunu garanti edemeyiz.</Paragraph>
      
      <SubHeading>7. Veri Saklama</SubHeading>
      <Paragraph>Kişisel bilgilerinizi, bu Gizlilik Politikası'nda belirtilen amaçları gerçekleştirmek için gerekli olan süre boyunca saklayacağız. Yasal yükümlülüklere uymak için daha uzun süreler boyunca saklamamız gerekebilir.</Paragraph>
      
      <SubHeading>8. Haklarınız</SubHeading>
      <Paragraph>Kişisel verilerinizle ilgili olarak aşağıdaki haklara sahipsiniz:</Paragraph>
      <ul>
        <li>Bilgi edinme hakkı</li>
        <li>Erişim hakkı</li>
        <li>Düzeltme hakkı</li>
        <li>Silme hakkı</li>
        <li>İşlemeyi kısıtlama hakkı</li>
        <li>Veri taşınabilirliği hakkı</li>
        <li>İtiraz hakkı</li>
      </ul>
      <Paragraph>Bu haklarınızı kullanmak veya gizlilik uygulamalarımız hakkında sorularınız için bizimle iletişime geçebilirsiniz.</Paragraph>
      
      <SubHeading>9. Politika Değişiklikleri</SubHeading>
      <Paragraph>Bu Gizlilik Politikası'nı zaman zaman güncelleyebiliriz. Güncellemelerden haberdar olmak için bu politikayı periyodik olarak gözden geçirmenizi öneririz. Önemli değişiklikler yapıldığında, sizi uygun bir şekilde bilgilendireceğiz.</Paragraph>
      
      <SubHeading>10. İletişim</SubHeading>
      <Paragraph>Gizlilik Politikamız veya kişisel verileriniz hakkında sorularınız veya endişeleriniz varsa, lütfen bizimle iletişime geçin:</Paragraph>
      <ul>
        <li>E-posta: destek@tkyexpress.com</li>
        <li>Telefon: 0850 611 06 69</li>
        <li>Adres: Üçevler, Bahçe Yolu Cd. No:32, 34514 Esenyurt/İstanbul</li>
      </ul>
    </Container>
  );
};

export default PrivacyPolicy;
