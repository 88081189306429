import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import BlogPage from './pages/BlogPage';
import FAQPage from './pages/FAQPage';
import ContactPage from './pages/ContactPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import DataOwnerApplicationForm from './pages/DataOwnerApplicationForm';
import TermsOfService from './pages/TermsOfService';
import DisclosureText from './pages/DisclosureText';
import CookiePolicy from './pages/CookiePolicy';
import ProtectionOfPersonalData from './pages/ProtectionOfPersonalData';

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/data-owner-application-form" element={<DataOwnerApplicationForm />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/disclosure-text" element={<DisclosureText />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/protection-of-personal-data" element={<ProtectionOfPersonalData />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
