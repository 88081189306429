import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 3rem 1.5rem;
  background-color: #fff;
  text-align: center;
  width: 100%;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.text};
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const Option = styled.div`
  background-color: #ffffff;
  color: ${({ theme }) => theme.colors.text};
  font-size: 1rem;
  line-height: 1.6;
  margin: 1rem;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: #ffffff;
  }
`;

const CourierOptions = () => {
  const handleNeedCourierClick = () => {
    window.location.href = 'http://musteri.tkyexpress.com/giris-yap';
  };

  const handleWantToBeCourierClick = () => {
    window.location.href = 'http://musteri.tkyexpress.com/kayit-ol';
  };

  return (
    <Section>
      <Title>Ne Yapmak İstiyorsunuz?</Title>
      <OptionsContainer>
        <Option onClick={handleNeedCourierClick}>
          Kuryeye İhtiyacım Var
        </Option>
        <Option onClick={handleWantToBeCourierClick}>
          Kurye Olmak İstiyorum
        </Option>
      </OptionsContainer>
    </Section>
  );
};

export default CourierOptions;
