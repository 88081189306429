import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { slide as Menu } from 'react-burger-menu';

const Nav = styled.nav`
  background: ${({ theme }) => theme.colors.primary};
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin: 0 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (max-width: 768px) {
    margin: 0.5rem 0;
  }
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.large};
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;

  & img {
    height: 40px;
    margin-right: 0.5rem;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const NavActions = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const LoginButton = styled.a`
  background: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.primary};
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  transition: background 0.3s, color 0.3s;

  &:hover {
    background: ${({ theme }) => theme.colors.text};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const BurgerMenu = styled(Menu)`
  @media (min-width: 769px) {
    display: none;
  }

  .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.text};
    transition: color 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  .bm-menu-wrap {
    width: 100% !important;
  }
`;

const Overlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
`;

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const handleClickOutside = () => {
    closeMenu();
  };

  return (
    <>
      <Nav>
        <Logo to="/">
          <img src="/logo.svg" alt="TkyExpress" />
        </Logo>
        <NavMenu>
          <NavLink to="/">Anasayfa</NavLink>
          {/* <NavLink to="/blog">Blog</NavLink> */}
          <NavLink to="/faq">Sıkça Sorulan Sorular</NavLink>
          <NavLink to="/contact">İletişim</NavLink>
        </NavMenu>
        <NavActions>
          <LoginButton href="http://musteri.tkyexpress.com/" target="_blank" rel="noopener noreferrer">Müşteri Girişi</LoginButton>
        </NavActions>
        <BurgerMenu right isOpen={menuOpen} onStateChange={handleStateChange} ref={menuRef}>
          <NavLink to="/" onClick={closeMenu}>Anasayfa</NavLink>
          {/* <NavLink to="/blog" onClick={closeMenu}>Blog</NavLink> */}
          <NavLink to="/faq" onClick={closeMenu}>Sıkça Sorulan Sorular</NavLink>
          <NavLink to="/contact" onClick={closeMenu}>İletişim</NavLink>
          <LoginButton href="http://musteri.tkyexpress.com/" target="_blank" rel="noopener noreferrer" onClick={closeMenu}>Müşteri Girişi</LoginButton>
        </BurgerMenu>
      </Nav>
      <Overlay isOpen={menuOpen} onClick={handleClickOutside} />
    </>
  );
};

export default Navbar;
