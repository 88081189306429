import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 3rem 1.5rem;
  background-color: #ffffff; /* Arkaplan rengini beyaz yap */
  text-align: center;
  width: 100%;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.text};
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const Text = styled.p`
  color: #000000; /* Metin rengini siyah yap */
  font-size: 1rem;
  line-height: 1.6;
  margin: 1rem 0;
  max-width: 600px;
`;

const Image = styled.img`
  width: 100%;
  max-width: 400px;
  margin: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const TKYExpress = () => {
  return (
    <Section>
      <Title>TKY Express Nedir?</Title>
      <Subtitle>Nasıl Çalışır?</Subtitle>
      <Content>
        <Image src="/image1.webp" alt="TKY Express" />
        <Text>
          TKY Express, hızlı ve güvenilir kurye hizmetleri sunan bir taşıma firmasıdır. Müşterilerimizin gönderilerini en kısa sürede ve güvenli bir şekilde teslim etmeyi amaçlıyoruz.
          <br/><br/>
          Nasıl çalışır?
          <br/><br/>
          1. Gönderinizi hazırlayın: Gönderinizi güvenli bir şekilde paketleyin ve teslimata hazır hale getirin.
          <br/>
          2. Kurye yönlendirmesi: Size en yakın kuryemizi yönlendirerek gönderinizi teslim alırız.
          <br/>
          3. Hızlı teslimat: Gönderiniz en kısa sürede alıcısına ulaştırılır.
          <br/><br/>
          TKY Express ile gönderileriniz her zaman güvende ve zamanında ulaşır.
        </Text>
      </Content>
    </Section>
  );
};

export default TKYExpress;
