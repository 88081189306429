import React from 'react';
import styled from 'styled-components';
import FaqItem from "../components/FaqItem";

const Container = styled.div`
  max-width: 800px;
  margin: 50px auto;
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
`;

const Heading = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
  font-weight: bold;
`;

const questions = [
  { question: 'TkyExpress Çalışma saatleriniz nedir?', answer: 'Müşteri ihtiyaçlarına göre esnek çalışma saatlerine sahibiz. Gönderilerinizi haftanın farklı günlerinde ve saatlerinde oluşturabilirsiniz.' },
  { question: 'TkyExpress kurye hizmetini neden tercih etmeliyim?', answer: 'Hızlı, güvenilir ve çeşitli hizmet seçenekleri sunan bir kurye firması olarak müşteri memnuniyetini en üst düzeyde tutuyoruz. Rekabetçi fiyatlarımız ve güvenilir kuryelerimizle en iyi deneyimi sunmak için buradayız.' },
  { question: 'Büyük veya ağır paketleri taşıyabilir misiniz?', answer: 'Evet, büyük ve ağır paketleri taşımak için uygun ekipmana ve deneyime sahibiz. İhtiyacınıza uygun araç ve kuryeyi seçerek en iyi hizmeti alabilirsiniz.' },
  { question: 'Gönderi oluşturma fiyatlandırmanız nasıl belirleniyor?', answer: 'Fiyatlandırma, hizmet türü, teslimat mesafesi, paket boyutu ve ağırlığı gibi faktörlere göre belirlenir. En adil ve rekabetçi fiyatları sunmak için çaba gösteriyoruz.' },
  { question: 'Hangi saatlerde hizmet veriyorsunuz?', answer: 'Teslimat saatleri hizmet türüne göre değişebilir. Ancak genel olarak günün farklı saatlerinde teslimat yapma esnekliğine sahibiz. Web sitemizden ve diğer iletişim kanallarından bize ulaşarak detaylı bilgi alabilirsiniz.' },
  { question: 'Gönderilerim güvende mi?', answer: 'Müşteri gizliliğine ve güvenliğine büyük önem veriyoruz. Özel eşyalarınızı korumak için gerekli önlemleri alıyor ve güvenilir kuryelerimizle taşıma yapıyoruz.' },
  { question: 'Kuryeleriniz güvenli mi?', answer: 'Evet, tüm kuryelerimiz özenle seçilmiş, güvenilir ve deneyimlidir. Teslimatlarınızın güvenliği bizim önceliğimizdir.' },
  { question: 'Hangi bölgelerde teslimat yapıyorsunuz?', answer: 'Başta İstanbul Ankara olmak üzere İzmir, Bursa, Antalya, Adana bölgelerinde şehir içi tüm bölgelere hizmet sunuyoruz. Web sitemizde üzerinden bize ulaşarak  teslimatlar hakkında detaylı bilgi alabilirsiniz.' },
  { question: 'Nasıl gönderi oluşturabilirim?', answer: 'Gönderi oluşturmak için web sitemizi ziyaret edebilir, mobil uygulamamızı kullanabilir veya telefon ile çağrı merkezimizi arayabilirsiniz. Siparişinizin en hızlı şekilde işleme alınması için gerekli bilgileri eksiksiz vermeniz önemlidir.' },
  { question: 'Teslimat ücretleri nedir?', answer: 'Teslimat ücretleri, hizmet türü, teslimat mesafesi ve faktörlere bağlı olarak değişebilir. Rekabetçi fiyatlarımızla en iyi hizmeti en uygun maliyetle sunmaya çalışıyoruz.' },
  { question: 'TkyExpress`de kurye olma şartları?', answer: 'TkyExpress`de kurye olmak için A1 ya da A2 ehliyet sahibi olunması gerekir. 50 CC motorlarla ve arabalı kurye modelinde çalışacaklar için ise B sınıfı ehliyet zorunludur. Ardından web sitemizde yer alan `Kurye olmak istiyorum` seçeneğini seçerek açılan sayfadaki belgeler eksiksiz yüklenmelidir. Bu belgeler: İkametgah Belgesi, Ruhsat fotografı, Araç fotoğrafı, Adli sicil kaydı ve Vergi levhası.' },
  { question: 'TkyExpress`de kuryeler kaç saat çalışır?', answer: 'TkyExpress`de kuryelerimiz; 7/24 dilediği saatler arasında çalışabilir.' },
  { question: 'TkyExpress oto gönderi atama nedir?', answer: 'TkyExpress`de firma restoran market benzeri işletmeler kullanıcıları tarafından oluşturulan gönderiler sistem tarafından araç tipinize göre size en yakın gönderi atanır.' },
];

const FAQPage = () => (
  <Container>
      <Heading>Sıkça Sorulan Sorular</Heading>
      {questions.map((item, index) => (
        <FaqItem key={index} question={item.question} answer={item.answer} />
      ))}
    </Container>
);

export default FAQPage;
