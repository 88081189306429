import React from 'react';
import styled from 'styled-components';
import CustomSlider from '../components/Slider';
import TKYExpress from '../components/TKYExpress';
import ContactUs from '../components/ContactUs';
import CourierOptions from '../components/CourierOptions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

const HomePage = () => (
  <Container>
    <CustomSlider />
    <TKYExpress />
    <ContactUs />
    <CourierOptions />
  </Container>
);

export default HomePage;
