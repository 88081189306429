import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 2rem;
  text-align: center;
`;

const BlogPage = () => (
  <Container>
    <h1>Blog</h1>
    <p>Welcome to our blog page.</p>
  </Container>
);

export default BlogPage;
