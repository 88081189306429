const theme = {
  colors: {
    primary: '#ffffff',
    secondary: '#ff4500',
    text: '#000000',
    background: '#f5f5f5',
  },
  fontSizes: {
    small: '0.8rem',
    medium: '1rem',
    large: '1.5rem',
  },
};

export default theme;
