import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const Container = styled.div`
  max-width: 600px;
  margin: 50px auto;
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
`;

const Heading = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
  font-weight: bold;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  margin-bottom: 5px;
  color: #555;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: border-color 0.3s;
  font-size: 16px;
  &:focus {
    border-color: #007bff;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  min-height: 120px;
  transition: border-color 0.3s;
  font-size: 16px;
  &:focus {
    border-color: #007bff;
  }
`;

const Button = styled.button`
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ff4500;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  &:hover {
    background-color: black;
  }
`;

const StatusMessage = styled.p`
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  background-color: ${props => props.success ? '#d4edda' : '#f8d7da'};
  color: ${props => props.success ? '#155724' : '#721c24'};
  border: 1px solid ${props => props.success ? '#c3e6cb' : '#f5c6cb'};
  text-align: center;
`;

function ContactPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState({ message: '', success: false });

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      name: name,
      email: email,
      phoneNumber: phoneNumber,
      message: message,
    };

    axios.post('http://api.tkyexpress.com/chat/contact', formData)
    .then(response => {
      setStatus({ message: 'Başarılı!', success: true });
    })
    .catch(error => {
      setStatus({ message: 'Başarısız!', success: false });
    });
  };

  return (
    <Container>
      <Heading>İletişim</Heading>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Ad Soyad:</Label>
          <Input 
            type="text" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            required 
          />
        </FormGroup>
        <FormGroup>
          <Label>E-Posta:</Label>
          <Input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
        </FormGroup>
        <FormGroup>
          <Label>Telefon Numarası:</Label>
          <Input 
            type="tel" 
            value={phoneNumber} 
            onChange={(e) => setPhoneNumber(e.target.value)} 
            required 
          />
        </FormGroup>
        <FormGroup>
          <Label>Mesaj:</Label>
          <Textarea 
            value={message} 
            onChange={(e) => setMessage(e.target.value)} 
            required 
          />
        </FormGroup>
        <Button type="submit">Gönder</Button>
        {status.message && (
          <StatusMessage success={status.success}>{status.message}</StatusMessage>
        )}
      </Form>
    </Container>
  );
}

export default ContactPage;
