import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }

  body {
    font-family: 'Roboto', sans-serif;
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    line-height: 1.6;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;

    @media (min-width: 769px) {
      display: none;
    }
  }

  .bm-burger-bars {
    background: ${({ theme }) => theme.colors.text};
  }

  .bm-menu {
    background: ${({ theme }) => theme.colors.primary};
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  .bm-item-list a {
    padding: 0.5em;
    display: block;
    color: ${({ theme }) => theme.colors.text};
  }

  .bm-menu-wrap {
    width: 100% !important;
  }

  @media (max-width: 768px) {
    .bm-menu-wrap {
      width: 100% !important;
    }
  }
`;

export default GlobalStyle;
