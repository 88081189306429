import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 800px;
  margin: 50px auto;
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
`;

const Heading = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 28px;
  font-weight: bold;
`;

const SubHeading = styled.h3`
  margin-top: 20px;
  color: #ff4500;
  font-size: 22px;
  font-weight: bold;
`;

const Paragraph = styled.p`
  margin: 10px 0;
  line-height: 1.6;
  color: #555;
`;

const CookiePolicy = () => {
  return (
    <Container>
      <Heading>Çerez Politikası</Heading>
      
      <SubHeading>1. Giriş</SubHeading>
      <Paragraph>Bu Çerez Politikası, TkyExpress tarafından web sitemizde çerezlerin nasıl kullanıldığını açıklamaktadır.</Paragraph>
      
      <SubHeading>2. Çerez Nedir?</SubHeading>
      <Paragraph>Çerezler, ziyaret ettiğiniz web siteleri tarafından tarayıcınıza gönderilen ve cihazınızda depolanan küçük metin dosyalarıdır. Çerezler, web sitesinin sizi tanımasını sağlar ve deneyiminizi kişiselleştirir.</Paragraph>
      
      <SubHeading>3. Çerez Türleri</SubHeading>
      <Paragraph>Web sitemizde farklı türlerde çerezler kullanıyoruz:</Paragraph>
      <ul>
        <li>Oturum Çerezleri: Bu çerezler geçici olarak cihazınızda depolanır ve tarayıcınızı kapattığınızda silinir.</li>
        <li>Kalıcı Çerezler: Bu çerezler cihazınızda belirli bir süre boyunca kalır veya siz onları manuel olarak silene kadar saklanır.</li>
        <li>Birinci Taraf Çerezler: Bu çerezler doğrudan bizim tarafımızdan yerleştirilir.</li>
        <li>Üçüncü Taraf Çerezler: Bu çerezler, iş ortaklarımız veya hizmet sağlayıcılarımız tarafından yerleştirilir.</li>
      </ul>
      
      <SubHeading>4. Çerezlerin Kullanım Amacı</SubHeading>
      <Paragraph>Çerezleri şu amaçlarla kullanıyoruz:</Paragraph>
      <ul>
        <li>Web sitemizin düzgün çalışmasını sağlamak</li>
        <li>Kullanıcı tercihlerini hatırlamak</li>
        <li>Web sitesi trafiğini analiz etmek</li>
        <li>Kişiselleştirilmiş içerik ve reklamlar sunmak</li>
      </ul>
      
      <SubHeading>5. Çerezleri Yönetme</SubHeading>
      <Paragraph>Tarayıcınızın ayarlarını değiştirerek çerezleri kontrol edebilir veya silebilirsiniz. Ancak, çerezleri devre dışı bırakmanız durumunda web sitemizin bazı işlevleri düzgün çalışmayabilir.</Paragraph>
      
      <SubHeading>6. Değişiklikler</SubHeading>
      <Paragraph>Bu Çerez Politikası'nı zaman zaman güncelleyebiliriz. Güncellemelerden haberdar olmak için bu politikayı periyodik olarak gözden geçirmenizi öneririz.</Paragraph>
      
      <SubHeading>7. İletişim</SubHeading>
      <Paragraph>Çerez Politikamız hakkında sorularınız veya endişeleriniz varsa, lütfen bizimle iletişime geçin:</Paragraph>
      <ul>
        <li>E-posta: destek@tkyexpress.com</li>
        <li>Telefon: 0850 611 06 69</li>
        <li>Adres: Üçevler, Bahçe Yolu Cd. No:32, 34514 Esenyurt/İstanbul</li>
      </ul>
    </Container>
  );
};

export default CookiePolicy;
