import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Section = styled.section`
  padding: 3rem 1.5rem;
  background-color: ${({ theme }) => theme.colors.background};
  text-align: center;
  width: 100%;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.text};
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.text};
  font-size: 1rem;
  line-height: 1.6;
  margin: 1rem 0;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const ContactUs = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact');
  };

  return (
    <Section>
      <Title>Merak ettikleriniz mi var?</Title>
      <Text>
        Merak ettikleriniz, şikayet ve önerileriniz için bizimle iletişime geçin.
      </Text>
      <Button onClick={handleContactClick}>İletişime Geçin</Button>
    </Section>
  );
};

export default ContactUs;
