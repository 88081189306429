import React, { useState } from 'react';
import styled from 'styled-components';

const ItemContainer = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
  transition: all 0.3s ease;
`;

const Question = styled.div`
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  color: #ff4500;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Answer = styled.div`
  margin-top: 10px;
  font-size: 16px;
  color: #555;
  max-height: ${props => (props.isOpen ? '200px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease;
`;

const Icon = styled.span`
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.3s ease;
`;

const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <ItemContainer>
      <Question onClick={toggleAnswer}>
        {question}
        <Icon isOpen={isOpen}>▼</Icon>
      </Question>
      <Answer isOpen={isOpen}>{answer}</Answer>
    </ItemContainer>
  );
};

export default FaqItem;
